import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import TermsOfService from '../components/slides/tos/TermsOfService';

const Tos = () => {
  return (
    <Layout>
      <SEO
        title="Terms of Service and Privacy Policy | Deadline Funnel"
        description="Terms of service and privacy policy for Deadline Funnel"
      />
      <TermsOfService />
    </Layout>
  )
};

export default Tos;